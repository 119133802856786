import { useEffect, useRef, useState } from 'react';
import { collection, query, getDocs } from "firebase/firestore";
import { Card, CardMedia, createTheme, ImageList, ImageListItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { db } from './utils/firebase_utils';
import { Container } from '@mui/system';
import { ThemeProvider } from '@emotion/react';
import { LinkedIn } from '@mui/icons-material';
import TwitterIcon from '@mui/icons-material/Twitter';
import CircleSketch from './sketches/CircleSketch';


const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});


function App() {
  const [reelItems, setReelItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const q = query(collection(db, "reel-items"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(x => x.data());

      setReelItems(data)
      console.log(data);
    }

    fetchData()

  }, []);

  const parentRef = useRef(null);

  return (
    <ThemeProvider theme={theme}>

      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid>
            <a style={{ color: "black" }} href="https://www.linkedin.com/in/karel-haerens-806729158/" target="_blank" rel="noreferrer">
              <LinkedIn></LinkedIn>
            </a>
          </Grid>
          <Grid>
            <a style={{ color: "black" }} href="https://www.youtube.com/user/96karel/featured" target="_blank" rel="noreferrer">
              <YouTubeIcon></YouTubeIcon>
            </a>
          </Grid>
          <Grid>
            <a style={{ color: "black" }} href="https://twitter.com/HaerensKarel" target="_blank" rel="noreferrer">
              <TwitterIcon></TwitterIcon>
            </a>
          </Grid>
        </Grid>
        <Typography variant="h3">Karel Haerens</Typography>
        <Typography variant="subtitle1">Some stuff made by me: </Typography>


        {
          window.innerWidth > 500 &&
          <Grid container spacing={5}>
            {[0, 1, 2].map(
              i => {
                return <Grid xs={4}>
                  <ImageList variant="masonry" cols={1} gap={10}>
                    {
                      i === 0 &&
                      <ImageListItem ref={parentRef}>
                        <CircleSketch parent={parentRef} />
                      </ImageListItem>
                    }
                    {reelItems
                      .filter(x => x.col === i)
                      .sort((a, b) => a.row - b.row)
                      .map((item, i) => {

                        return <ImageListItem key={item['cover-image']} onMouseOver={() => console.log(item['priority'])}>
                          <Card variant="elevated">
                            <CardMedia
                              component={item['type']}
                              image={item['cover-image']}
                              alt={item.title}
                              frameborder="0"
                              controls
                            />
                          </Card>
                        </ImageListItem>
                      }
                      )}

                  </ImageList>

                </Grid>
              }
            )}
          </Grid>
        }

        {
          window.innerWidth <= 500 &&

          <ImageList variant="masonry" cols={1} gap={10}>

            <ImageListItem ref={parentRef}>
              <CircleSketch parent={parentRef} />
            </ImageListItem>

            {reelItems
              .sort((a, b) => a.row - b.row)
              .map((item, i) => {
                return <ImageListItem key={item['cover-image']} onMouseOver={() => console.log(item['priority'])}>
                  <Card variant="elevated">
                    <CardMedia
                      component={item['type']}
                      image={item['cover-image']}
                      alt={item.title}
                      frameborder="0"
                      controls
                    />
                  </Card>
                </ImageListItem>
              }
              )}

          </ImageList>
        }



        {/*
        <ImageList variant="masonry" cols={3} gap={3}>
          <ImageListItem ref={parentRef}>
            <CircleSketch parent={parentRef} />
          </ImageListItem>
          {reelItems.map((item, i) => {

            return <ImageListItem key={item['cover-image']} onMouseOver={() => console.log(item['priority'])}>
              <Card variant="elevated">
                <CardMedia
                  component={item['type']}
                  image={item['cover-image']}
                  alt={item.title}
                  frameborder="0"
                  controls
                />
              </Card>
            </ImageListItem>
          }
          )}

        </ImageList> */}

      </Container>
    </ThemeProvider>
  );
}

export default App;
