// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";



const firebaseConfig = {
  apiKey: "AIzaSyBoeMkODO-P8iJJNo-DwgdgW0YOPrz7-_M",
  authDomain: "karelhaerens-ad79b.firebaseapp.com",
  projectId: "karelhaerens-ad79b",
  storageBucket: "karelhaerens-ad79b.appspot.com",
  messagingSenderId: "7314748086",
  appId: "1:7314748086:web:4fe606ee696b7e4d6f6c17",
  measurementId: "G-31KLMP5SF3"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

