import React from 'react'
import Sketch from 'react-p5'

let i = 0;
let w;
let h;
let points;
let num_points = 30
let num_lines = 30
let slider;


class Point {
  constructor(angle, radius, v, a, ex_x, ex_y, color) {
    this.angle = angle;
    this.start_angle = angle
    this.radius = radius;
    this.v = v;
    this.a = a;
    this.ex_x = ex_x;
    this.ex_y = ex_y;
    this.color = color;

    this.x = 0;
    this.y = 0;

    this.set_coords();

  }

  update() {
    this.angle = (this.angle + this.v) % (Math.PI * 2);
    this.v += this.a;
    this.set_coords();
  }

  set_coords() {
    this.x = this.radius * this.ex_x * Math.cos(this.angle) + Math.floor(w / 2);
    this.y = this.radius * this.ex_y * Math.sin(this.angle) + Math.floor(h / 2);
  }

}

function init() {
  points = [];
  for (let i = 0; i < num_points; i++) {
    let angle = i * (2 * Math.PI) / num_points
    let radius = w / 4  // (w/20) * (1/num_points) * (1 + i)
    let v = ((i + 1) / num_points) * (1 / 30) + 0.001
    let a = 0
    let ex_x = 0.9 + (w / 400) * (1 / num_points) * (1 + i)
    let ex_y = 0.9 - (h / 400) * (1 / num_points) * (1 + i)

    let c = `hsl(${angle * (360 / (2 * Math.PI))},100%,100%)`;
    points.push(new Point(angle, radius, v, a, ex_x, ex_y, c))
  }
}

function numPointsChanged() {
  num_points = this.value()
  init()
}

function numLinesChanged() {
  num_lines = this.value()
}

function CircleSketch({ parent }) {

  const setup = (p5, canvasParentRef) => {

    w = canvasParentRef.offsetWidth;
    h = canvasParentRef.offsetWidth;
    p5.createCanvas(w, h).parent(canvasParentRef)
    p5.frameRate(30);
    p5.colorMode(p5.HSL, 360, 100, 100, 1);
    let inpNumpPoints = p5.createInput(num_points);
    let inpNumLines = p5.createInput(num_lines);

    inpNumpPoints.size(50);
    inpNumpPoints.input(numPointsChanged);
    inpNumpPoints.parent(canvasParentRef)
    inpNumpPoints.position(0, h - 20)

    inpNumLines.size(50);
    inpNumLines.input(numLinesChanged);
    inpNumLines.parent(canvasParentRef)
    inpNumLines.position(51, h - 20)

    slider = p5.createSlider(1, 20, 100);
    slider.parent(canvasParentRef)
    slider.position(110, h - 20)


    init()

  }

  const draw = p5 => {

    function linspace(startValue, stopValue, cardinality) {
      var arr = [];
      var step = (stopValue - startValue) / (cardinality - 1);
      for (var i = 0; i < cardinality; i++) {
        arr.push(startValue + (step * i));
      }
      return arr;
    }

    const zip = (...rows) => [...rows[0]].map((_, c) => rows.map(row => row[c]))

    function get_interpolated_points(point1, point2, point3, num_lines) {
      let [x1, y1] = [point1.x, point1.y];
      let [x2, y2] = [point2.x, point2.y];
      let [x3, y3] = [point3.x, point3.y];

      let xs_start = linspace(x1, x2, num_lines)
      let xs_end = linspace(x2, x3, num_lines)

      let ys_start = linspace(y1, y2, num_lines)
      let ys_end = linspace(y2, y3, num_lines)

      let start_points = zip(xs_start, ys_start)
      let end_points = zip(xs_end, ys_end)

      let c1 = p5.color(point1.angle * (360 / (2 * Math.PI)), 100, 50)
      let c2 = p5.color(point2.angle * (360 / (2 * Math.PI)), 100, 50)

      p5.colorMode(p5.RGB, 255, 255, 255, 1)

      let [r1, g1, b1] = [p5.red(c1), p5.green(c1), p5.blue(c1)]
      let [r2, g2, b2] = [p5.red(c2), p5.green(c2), p5.blue(c2)]

      let rs = linspace(r1, r2, num_lines)
      let gs = linspace(g1, g2, num_lines)
      let bs = linspace(b1, b2, num_lines)

      let new_colors = []

      for (let i in rs) {
        new_colors.push(p5.color(rs[i], gs[i], bs[i], 0.5))
      }

      p5.colorMode(p5.HSL, 360, 100, 100, 1);

      return [start_points, end_points, new_colors]
    }

    function draw_interpolations(point_list, num_lines, step) {
      for (let i = 0; i < point_list.length; i++) {

        let point1 = point_list[(i + step) % point_list.length];
        let point2 = point_list[(i + step * 2) % point_list.length];
        let point3 = point_list[(i + step * 3) % point_list.length];

        let [start_points, end_points, colors] = get_interpolated_points(point1, point2, point3, num_lines)

        for (let j in start_points) {
          let [x1, y1] = start_points[j]
          let [x2, y2] = end_points[j]
          p5.stroke(colors[j])
          p5.line(x1, y1, x2, y2)
        }
      }
    }



    p5.background(360, 100, 100);
    let step = slider.value()
    for (let j in points) {
      let p = points[j]
      p.update(0)
      //p.radius = p.radius + Math.cos(i / (2 * Math.PI) * 1 / 20) * w / 50
      p.ex_x = 1 - (w / 400) * (num_points) / num_points + 2 * (w / 400) * ((i + j) % num_points) / num_points
      p.ex_y = 1 - (w / 400) * (num_points) / num_points + 2 * (w / 400) * ((i + j + num_points / 2) % num_points) / num_points
      i += 1
    }
    draw_interpolations(points, num_lines, step)
    p5.fill(0, 0, 0);
    p5.noStroke()
    p5.textSize(20)
    p5.text('try me!', 0, h-30);
  }
  return <Sketch setup={setup} draw={draw} />
}

export default CircleSketch